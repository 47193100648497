var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.response)?_c('b-container',{attrs:{"fluid":""}},[(_vm.$route.name === 'LibraryArea')?_c('b-row',{staticClass:"head-container",style:({
        backgroundImage: ("linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.99) 99% ), url(" + (this.getBannerImage()) + ")"),
      })},[(!_vm.isMobile())?[_c('b-col',{staticClass:"text-center text-light c-container-banner-title",attrs:{"cols":"12"}},[_c('img',{staticClass:"d-none d-sm-inline-block trackd-icon",class:{ 'd-xl-none': !_vm.isAuthenticated() },attrs:{"src":require("../../assets/trackd-icon-white.svg")}}),(!_vm.isAuthenticated())?_c('h1',{staticClass:" font-weight-light c-banner-title pt-3 pt-md-4 mt-md-3"},[_c('span',{staticClass:"d-none d-xl-block"},[_vm._v("Welcome to Trackd")]),_c('span',{staticClass:"d-xl-none"},[_vm._v("My Collection")])]):_vm._e(),(_vm.isAuthenticated())?_c('h1',{staticClass:" font-weight-light c-banner-title pt-3 pt-md-4 mt-md-3"},[_c('span',{staticClass:"d-sm-none d-xl-block"},[_vm._v("Welcome "+_vm._s(this.username()))]),_c('span',{staticClass:"d-none d-sm-block d-xl-none"},[_vm._v("My Collection")])]):_vm._e()])]:_vm._e()],2):(_vm.response.banner.image.length > 0)?_c('b-row',{staticClass:"image-backdrop",style:({
        backgroundImage: "url('../../../assets/library/library-welcome.png');",
      })},[(!_vm.isMobile())?[_c('b-col',{staticClass:"text-center text-light c-container-banner-title",attrs:{"cols":"12"}},[(_vm.response.banner.title)?_c('h1',{staticClass:" font-weight-light c-banner-title"},[_vm._v(" "+_vm._s(_vm.response.banner.title)+" ")]):_vm._e()])]:[_c('b-col',{staticClass:"col-2 pr-0 pl-0",attrs:{"cols":"2"}},[_c('b-button',{staticClass:"pl-0 pb-0 pr-0",staticStyle:{"height":"20px"},attrs:{"variant":"link"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{staticClass:"align-top",attrs:{"src":require("../../assets/back-icon-mobile.svg"),"height":"30","alt":"Logo"}})])],1),_c('b-col',{staticClass:"text-center text-light mobile-banner-padding",attrs:{"cols":"8"}},[_c('b-row',{attrs:{"align-h":"center"}},[(_vm.response.banner.title)?_c('h2',{staticClass:"mx-auto font-weight-light mobile-header-title"},[_vm._v(" "+_vm._s(_vm.response.banner.title)+" ")]):_vm._e()])],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-row',{staticClass:"pt-7 mt-2 ml-2"},[_c('b-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"12","align-self":"end"}},[_c('b-button',{staticClass:"pl-0 pb-0 pr-0",staticStyle:{"height":"20px"},attrs:{"variant":"link"}},[_c('img',{staticClass:"align-top",attrs:{"src":require("../../assets/filter-icon.svg"),"height":"22","alt":"Logo"}})])],1)],1)],1)]],2):_c('b-row',{staticClass:"image-backdrop",class:_vm.random_colour},[(!_vm.isMobile())?[_c('b-col',{staticClass:"text-center text-light c-container-banner-title mx-auto",attrs:{"cols":"12"}},[(_vm.response.banner.title == 'Discover Library')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('img',{staticClass:"c-banner-logo",attrs:{"src":require("../../assets/trackd-icon-white.svg"),"blank-color":"#ccc","alt":"placeholder"}})])],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.response.banner.title)?_c('h1',{staticClass:"mx-auto font-weight-light c-banner-title",class:{
                  'c-banner-title--with-logo':
                    _vm.response.banner.title == 'Discover Library',
                }},[_vm._v(" "+_vm._s(_vm.response.banner.title)+" ")]):_vm._e()])],1)],1)]:[_c('b-col',{staticClass:"col-2 pr-0 pl-0",attrs:{"cols":"2"}},[_c('b-button',{staticClass:"pl-0 pb-0 pr-0",staticStyle:{"height":"20px"},attrs:{"variant":"link"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{staticClass:"align-top",attrs:{"src":require("../../assets/back-icon-mobile.svg"),"height":"30","alt":"Logo"}})])],1),_c('b-col',{staticClass:"text-center text-light mobile-banner-padding",attrs:{"cols":"8"}},[_c('b-row',{attrs:{"align-h":"center"}},[(_vm.response.banner.title)?_c('h2',{staticClass:"mx-auto font-weight-light mobile-header-title"},[_vm._v(" "+_vm._s(_vm.response.banner.title)+" ")]):_vm._e()])],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-row',{staticClass:"pt-7 mt-2 ml-2"},[_c('b-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"12","align-self":"end"}},[_c('b-button',{staticClass:"pl-0 pb-0 pr-0",staticStyle:{"height":"20px"},attrs:{"variant":"link"}},[_c('img',{staticClass:"align-top",attrs:{"src":require("../../assets/filter-icon.svg"),"height":"22","alt":"Logo"}})])],1)],1)],1)]],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }